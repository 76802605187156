body {
  background-color: #f6f9ff;
}

row label {
  justify-content: end;
}

h3 {
  display: flex;
}

header,
header .navbar {
  background-color: #fff !important;
  opacity: 0.8;
  position: fixed;
  z-index: 999;
}

.navbar-brand {
    margin-left: 25px;
}

header a {
  color: #244b78 !important;
}

footer,
footer nav {
  background-color: #567a9f;
  color: #fff;
  font-family: "Roboto", Regular;
  font-size: 12px;
  opacity: 0.8;
  z-index: 1050 !important; /* keep value lower than modal! */
}

footer a {
  color: #fff;
}

footer a:hover {
  color: #fff;
}

a.undecorated {
  color: white;
}

a.undecorated:hover {
  color: white;
  text-decoration: none;
}

.card-header[aria-expanded=false] .chevron-up-icon {
  display: none;
}

.card-header[aria-expanded=true] .chevron-down-icon {
  display: none;
}

.section-header[aria-expanded=false] .chevron-down-icon {
  display: none;
}

.section-header[aria-expanded=true] .chevron-right-icon {
  display: none;
}

.toast-container {
  margin-top: 6rem;
}

.list-group-numbered .list-group-item::before {
  content: none;
}